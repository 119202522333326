*{
  font-family: 'Montserrat', sans-serif;
}

html,
body{
  height: 100vh;
  width: 100%;
  margin: 0;
  
}

#root,
.App,
main{
 height: 100%;
}



