.add-listing-container {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;
    padding-bottom: 44.5px;
    display: flex;
    justify-content: center;
    background-color: #F4F4F7;
}


.new-listing-container {
    background-color: white;
    height: 450px;
    width: 1820px;
    color: #0C0C21;
    font-size: 14px;
}

.new-listing-heading {
    display: flex;
    align-items: center;
    position: relative;
    height: 83px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00347026;
    border-radius: 5px;
}

.new-listing-heading span {
    font-size: 20px;
    font-weight: bold;
}

.x-icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.new-listing {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

#save-listing {
    position: absolute;
    height: 38px;
    right: 14px;
    background: #1F1DEB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#save-listing:hover {
    opacity: 0.7;
}

#save-listing-mobile {
    position: absolute;
    height: 42px;
    width: 344px;
    background: #1F1DEB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding: 10px;
    border: none;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 75px;
}

#save-listing-mobile:hover {
    opacity: 0.7;
}


.new-listing-general-info {
    margin-top: 47px;
    margin-left: 22px;
    margin-right: 20px;
    position: relative;
}

.listing-form-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: #1F1DEB;
    width: 100%;
}

#general-info {
    font-size: 18px;
    font-weight: 600;
}


.new-listing-form {
    margin-top: 22px;
}

.listing-form-container {
    display: flex;
    position: relative;
    padding-bottom: 34px;
}

.new-listing-form label {
    font-size: 14px;
}

.new-listing-form-section {
    width: 500px;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
}

.new-listing-form-section input {
    width: 500px;
    height: 42px;
    border: 0.5px solid #0C0C21;
    border-radius: 5px;
    color: #0C0C21;
    font-weight: 600;
    font-size: 16px;
    padding-left: 10px;
}

#form-price-section {
    width: 200px;
    position: relative;
}

#form-price-section input {
    width: 200px;
}

#form-price-section span {
    content: 'BGN';
    position: absolute;
    color: #0C0C21;
    font-size: 14px;
    top: 32px;
    right: 0;
}

#form-price-section span::after {
    content: '';
    position: absolute;
    background-color: #0C0C21;
    height: 25px;
    width: 1px;
    top: -3px;
    left: -10px;

}

.form-photos {
    margin-top: 31px;
    display: flex;
    flex-direction: column;
}


#new-listing-photos-span {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}


.form-photos-container {
    display: flex;
}

.form-photos-section {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 10px;
}

.form-photos-section label {
    font-size: 16px;
}

.form-photos-section span {
    margin-bottom: 10px;
}


#form-photos-main-section {
    margin-right: 30px;
}


.photo-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.form-photos-section label::after {
    content: '';
    height: 18px;
    width: 18px;
    background-image: url('../../images/crossBlue.png');
    position: absolute;
    background-size: cover;

    left: 40px;
}

.form-photos-section label {
    border: 0.5px dashed #1F1DEB;
    height: 42px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #1F1DEB;
    font-weight: bold;
    cursor: pointer;
}

#photos-label-mobile {
    border: 0.5px dashed #1F1DEB;
    height: 42px;
    width: 42px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #1F1DEB;
    font-weight: bold;
    cursor: pointer;
    position: relative;
}

#photos-label-mobile::after {
    content: '';
    height: 18px;
    width: 18px;
    background-image: url('../../images/crossBlue.png');
    position: absolute;
    background-size: cover;
    left: 12px;
}

.photo-uploaded {
    color: #1F1DEB;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 200px;
    border: 0.5px solid #1F1DEB;
    border-radius: 5px;
    font-weight: bold;
    margin-right: 10px;
}

.red-x {
    position: absolute;
    content: '';
    background-image: url('../../images/redX.png');
    background-size: cover;
    width: 25px;
    height: 25px;
    top: 8px;
    right: 5px;
    cursor: pointer;
}

.uploaded-image-container {
    display: flex;
    align-items: center;
    height: 44px;
    padding-top: 28px;
}


@media screen and (min-width:1951px) {
    .new-listing-container {
        height: 775px;
       
    }
    
}

@media screen and (max-width:1950px) {
    .new-listing-container {
        height: 560px;
       
    }
    
}

@media screen and (max-width:1450px) {


    .new-listing-container {
        width: 1350px;
    
       
    }

 
    .listing-form-container label {
        margin-bottom: 9.9px;
    }

    /* .new-listing-form-section {
        margin-top: 29px;
    }

    #new-listing-brand {
        margin-top: 21.48px;
    } */

    .new-listing-form-section,
    .new-listing-form-section input {
        width: 344px;
    }

  

   


    #form-price-section span {
        top: 40px;
    }



}

@media screen and (max-width:1380px) {
    .new-listing-container {
        width: 1280px;
    }
}



@media screen and (max-width:1310px) {
    .new-listing-container {
        width: 1210px;
    }
}

@media screen and (max-width:1240px) {
    .new-listing-container {
        width: 1140px;
    }
}



@media screen and (max-width:1100px) {
    .new-listing-container {
        width: 1000px;
    }
}

@media screen and (max-width:1030px) {
    .new-listing-container {
        width: 930px;
    }
}


@media screen and (max-width:960px) {
    .new-listing-container {
        width: 860px;
    }
}


@media screen and (max-width:1150px) {

 
   

    .new-listing-form-section {
        width: 300px;
    }

    .new-listing-form-section input {
        width: 300px;
    }

    #form-price-section input,
    #form-price-section {
        width: 156px;
    }

}




@media screen and (max-width:950px) {



    .listing-form-container {
        flex-direction: column;
    }

    .listing-form-container label {
        margin-bottom: 9.9px;
    }

    .new-listing-form-section {
        margin-top: 29px;
    }

    #new-listing-brand {
        margin-top: 21.48px;
    }

    .new-listing-form-section input {
        width: 344px;
    }

    

    .new-listing-container {
        height: 745px;
    }

    .new-listing span {
        font-size: 16px;
    }

    .new-listing-heading {
        height: 53px;
    }

    #form-price-section span {
        top: 40px;

    }

    .form-photos-container {
        flex-direction: column;
    }

    .form-photos-section {
        margin-top: 27.5px;
    }

    #form-photos-main-section {
        margin-top: 14.9px;
    }



    #save-listing-mobile {
        display: flex;
    }


    #save-listing {
        display: none;
    }

    .new-listing-general-info {
        margin-top: 29px;

    }

    .new-listing-heading {
        box-shadow: none;

    }

    #photos-label-mobile {
        display: flex;
    }

    .form-photos-section label {
        display: none;
    }
}


@media screen and (min-width:768px) and (max-width:949px) {



    .new-listing-container {
        height: 980px;
    }

    .new-listing-form-section {
        width: 500px;
        margin-right: 0;
    }

    .new-listing-form-section input {
        width: 500px;
    }

    #form-price-section input,
    #form-price-section {
        width: 356px;
    }

    #save-listing-mobile {
        width: 500px;
    }

    .form-photos-section,
    .form-photos-container {
        width: 150px;
    }

}



@media screen and (min-width:691px) and (max-width:767px) {



    .new-listing-container {
        height: 980px;
    }

    .new-listing-form-section {
        width: 500px;
        margin-right: 0;
    }

    .new-listing-form-section input {
        width: 500px;
    }

    #form-price-section input,
    #form-price-section {
        width: 356px;
    }

    #save-listing-mobile {
        width: 500px;
    }

}


@media screen and (max-width:690px) {

    .new-listing-container {
        width: 374px;
        height: 980px;
    }

    .new-listing-general-info {
        margin-left: 15px;
        margin-right: 15px;
    }

    .new-listing-form-section,
    #form-price-section {
        width: 334px;
        margin-right: 0;
    }

    .new-listing-form-section input,
    #form-price-section input {
        width: 334px;
    }

    #new-listing-photos-span {
        font-size: 14px;
    }

    .form-photos-section span {
        font-size: 16px;
    }



}




@media screen and (max-width:455px) {

    .new-listing-container {
        width: 374px;
    }

    .new-listing-heading span {
        font-size: 16px;
    }

    #general-info {
        font-size: 14px;
    }

}




@media screen and (max-width:395px) {

    .new-listing-container {
        width: 344px;
        height: 980px;
    }

    .new-listing-general-info {
        margin-left: 15px;
        margin-right: 15px;
    }

    .new-listing-form-section,
    #form-price-section {
        width: 304px;
        margin-right: 0;
    }

    .new-listing-form-section input,
    #form-price-section input {
        width: 294px;
    }

    #save-listing-mobile {
        width: 304px;
    }

}



@media screen and (min-width:320px) and (max-width:375px) {

    .new-listing-container {
        width: 314px;
        height: 980px;
    }

    .new-listing-general-info {
        margin-left: 15px;
        margin-right: 15px;
    }

    .new-listing-form-section,
    #form-price-section {
        width: 274px;
        margin-right: 0;
    }

    .new-listing-form-section input,
    #form-price-section input {
        width: 264px;
    }

    #save-listing-mobile {
        width: 274px;
    }
}