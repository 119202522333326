.delete-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../images/modal-background.png');
    z-index: 12;
    color: #0C0C21;
}

.delete-modal {
    width: 578px;
    height: 189px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00347026;
    border-radius: 5px;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

#delete-listing-span {
    font-size: 20px;
    font-weight: bold;
}


#delete-confirmation-span {
    font-size: 16px;
    margin-top: 19px;
}


.modal-buttons {
    margin-top: 50px;
    display: flex;
    justify-content: center
}

.go-back-btn,
.delete-btn {
    border-radius: 5px;
    width: 230px;
    height: 42px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-left: 19px;
    margin-right: 19px;
}


.delete-btn:hover{
    opacity: 0.8;
}

.delete-btn {
    background: #EB1D6C 0% 0% no-repeat padding-box;
    color: white;
}

.go-back-btn {
    color: #0C0C21;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #1F1DEB;
}


@media screen and (max-width:950px) {

.delete-modal-container{
    background-image: none;
}


.delete-modal{
    height: 174px;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 12;
    padding-top: 20px;
    padding-bottom: 25px;
    padding-left: 0;
    padding-right: 0;
}



.go-back-btn,
.delete-btn {
    width: 172px;
    height: 42px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    margin-left: 19px;
    margin-right: 19px;
}



.modal-buttons{
    margin-top: 27px;
}

#delete-confirmation-span{
    height: 43px;
    text-align: center;
}

#delete-listing-span{
    text-align: center;
}


.manage-dropdown-mobile{
    display: none ;
}

}