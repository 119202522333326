.nav-container * {
    font-size: 14px;
    text-decoration: none;
}

.navigation {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 63px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
}

.nav-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}

.logo {
    position: absolute;
    top: 10px;
    left: 50px;
    width: 189px;
    height: 44px;
}

.nav-login-container {
    color: #0C0C21;
    position: relative;
    margin-right: 34px;
    height: 38px;
}

.nav-login-container::after {
    content: '';
    position: absolute;
    background-color: #0C0C21;
    height: 38px;
    width: 1px;
    right: -17px;
    top: 0;
}

.nav-login {
    color: #0C0C21;
}

.nav-login,
.nav-add-listing {
    display: flex;
    align-items: center;
    height: 38px;
}


.nav-greet-span {
    cursor: default;
    font-weight: bold;
}


.profile-icon {
    width: 24px;
    height: 24px;
}

.nav-login-add {
    position: absolute;
    right: 64px;
    display: flex;
    align-items: center;
    height: 100%;
}

.nav-add-listing {
    width: 140px;
    background-color: #1F1DEB;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.nav-add-listing:hover {
    opacity: 0.7;
}

.nav-add-listing span {
    color: #FFFFFF;
    font-weight: bold;

}

.add-listing-icon {
    width: 18px;
    height: 18px;
}



@media screen and (max-width:767px) {

    .profile-icon {
        width: 19.6px;
        height: 19.97px;
    }

    .nav-login span {
        display: none;
    }

    .logo {
        width: 160.94px;
        height: 37.46px;
        left: 20px;
    }

    .nav-login-container,
    .nav-login-container::after {
        height: 30px;
    }


    .nav-login-add {
        right: 20px;
    }

    .nav-add-listing {
        width: 30px;
        height: 30px;
    }

    .nav-add-listing span {
        display: none;
    }
}