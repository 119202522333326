.listings-container {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;
    padding-bottom: 44.5px;
    display: flex;
    justify-content: center;
    background-color: #F4F4F7;
    height: 730px;

}

.listings {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 22px;
    padding-right: 20.5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00347026;
    border-radius: 5px;
    overflow-y: hidden;

}



.listings {
    font-weight: bold;
    color: #0C0C21;
    font-size: 20px;
}


.cars-container {
    display: flex;
    flex-direction: column;
    margin-top: 38px;

    height: 690px;

}

.car-listings {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 287px 287px 287px 287px;
    row-gap: 30px;
    column-gap: 30.5px;
    overflow-y: scroll;
    height: 690px;

}

.car-listings::-webkit-scrollbar {
    display: none;
}

.car-container {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    height: 287px;
    width: 422px;
    position: relative;
}

.car-container img {
    object-fit: cover;
    height: 200px;
    width: 421.5px;
    border-radius: 10px;
    z-index: 1;
}

.car-name-price-container {
    height: 85px;
    font-size: 16px;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00347026;
    border: 1px solid #C9C9E3;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 0;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    padding-left: 14px;
}

#car-name {
    color: #0C0C21;
    margin-bottom: 7px;
}

#car-price {
    color: #1F1DEB;
}




.manage-button-container {
    display: flex;
    flex-direction: column;
}

.manage-button {
    width: 110px;
    height: 36px;
    background-color: #1F1DEB;
    position: absolute;
    color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 3;

}

.manage-button:hover {
    background-color: rgb(93, 91, 235);
}

.manage-button>.setting-icon {
    width: 17.68px;
    height: 18px;
    margin-right: 6px;
}

.manage-dropdown {
    display: flex;
    flex-direction: column;
    color: #0C0C21;
    font-size: 14px;
    font-weight: bold;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00347026;
    border-radius: 5px;
    width: 142px;
    height: 84px;
    position: absolute;
    right: -26px;
    top: 50px;
    z-index: 3;
    justify-content: center;
}

.manage-dropdown::after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    top: -15px;
    left: 0;
    z-index: -1;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid white;
}

.dropdown-edit>.dropdown-edit-image {
    width: 15.61px;
    height: 15.61px;
    margin-right: 5px;
    object-fit: contain;

}

.dropdown-delete>.dropdown-delete-image {
    width: 12.37px;
    height: 15.9px;
    margin-right: 5px;
    object-fit: contain;

}


.dropdown-delete,
.dropdown-edit {
    margin-left: 10px;
    text-decoration: none;
    color: #0C0C21;
    display: flex;
    align-items: center;
}

.dropdown-edit {
    margin-bottom: 19px;
    position: relative;
}

.dropdown-edit::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 90%;
    background-color: #1F1DEB;
    left: 0;
    bottom: -10px;
}


.manage-mobile-modal {
    content: '';
    height: 100%;
    width: 100%;
    background-image: url('../../images/modal-background.png');
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}




@media screen and (min-width:1951px) {

    .listings-container,
    .cars-container,
    .car-listings {
        height: 770px;
    }
}



@media screen and (max-width:1950px) {
    .car-container {
        width: 372px;
        height: 277px;
    }

    .car-container img {
        width: 372px;
        height: 190px;
    }



    .listings,
    .car-listings {
        width: 1630.5px;
    }

    .listings-container,
    .cars-container,
    .car-listings {
        height: 700px;
    }


}

@media screen and (max-width:1800px) {
    .car-container {
        width: 352px;
        height: 277px;
    }

    .car-container img {
        width: 352px;
        height: 190px;
    }


    .listings,
    .car-listings {
        width: 1530.5px;
    }

}


@media screen and (max-width:1700px) {


    .car-container {
        width: 322px;
        height: 277px;
    }

    .car-container img {
        width: 322px;
        height: 190px;
    }


    .listings,
    .car-listings {
        width: 1410px;
    }




}

@media screen and (max-width:1550px) {

    .car-container {
        width: 272px;
        height: 277px;
    }

    .car-container img {
        width: 272px;
        height: 190px;
    }


    .listings,
    .car-listings {
        width: 1222px;
    }




}

@media screen and (max-width:1350px) {

    .car-container {
        width: 222px;
        height: 277px;
    }

    .car-container img {
        width: 222px;
        height: 190px;
    }


    .listings,
    .car-listings {
        width: 1022px;
    }

    .car-name-price-container {
        height: 77px;
    }

}

@media screen and (max-width:1150px) {

    .car-container {
        width: 172px;
        height: 277px;
    }

    .car-container img {
        width: 172px;
        height: 190px;
    }


    .listings,
    .car-listings {
        width: 822px;
    }


}


@media screen and (min-width:951px) {
    .manage-mobile-modal {
        display: none !important;
    }

    .manage-dropdown-mobile {
        display: none !important;
    }
}

@media screen and (max-width:950px) {

    .car-listings {
        grid-template-columns: auto;
        grid-template-rows: 287px;
        justify-content: center;
    }

    .listings-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 45px;
        padding-bottom: 44.5px;

    }

    .car-container {
        width: 522px;
        height: 287px;
    }

    .car-container img {
        width: 521.5px;
        height: 200px;
    }


    .listings {
        height: 650px;
    }

    .listings,
    .car-listings {
        width: 564.5px;
    }

    .car-listings {
        height: 600px;
    }

    .manage-button {
        width: 30px;
        height: 30px;
    }

    .manage-button>.setting-icon {
        height: 14px;
        width: 14px;
        margin-right: 0;
    }

    .manage-button span {
        display: none;
    }

    .manage-dropdown {
        display: none;
    }

    .manage-dropdown-mobile span {
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }

    .manage-dropdown-mobile {
        height: 174px;
        background-color: white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 11;
    }

    .dropdown-edit>.dropdown-edit-image {
        width: 25px;
        height: 25px;

    }

    .dropdown-delete>.dropdown-delete-image {
        width: 20px;
        height: 31px;
    }

    .dropdown-edit,
    .dropdown-delete {
        margin-left: 20px;
        width: 180px;
    }

    .dropdown-edit::after {
        display: none;
    }


    .manage-dropdown-mobile::after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: #1F1DEB;
        top: 108px;
        left: 20px;
        right: 20px;
    }


}


@media screen and (max-width:690px) {



    .car-container {
        width: 422px;
        height: 287px;
    }

    .car-container img {
        width: 421.5px;
        height: 200px;
    }


    .listings,
    .car-listings {
        width: 464.5px;
    }

    .listings {
        height: 640px;
    }

    .car-listings {
        height: 600px;
    }

    .cars-container {
        height: 640px;
    }


}


@media screen and (max-width:590px) {



    .car-container {
        width: 344px;
        height: 234px;
    }

    .car-container img {
        width: 343.5px;
        height: 163px;
    }


    .listings,
    .car-listings {
        width: 374px;
    }



    .car-name-price-container {
        height: 71px;
    }


}



@media screen and (max-width:495px) {


    .car-container {
        width: 304px;
        height: 234px;
    }

    .car-container img {
        width: 303.5px;
        height: 163px;
    }


    .listings,
    .car-listings {
        width: 334px;
    }

}


@media screen and (max-width:455px) {


    .car-container {
        width: 304px;
        height: 234px;
    }

    .car-container img {
        width: 303.5px;
        height: 163px;
    }


    .listings,
    .car-listings {
        width: 360px;
    }

    .listings-container {
        height: 750px;
    }

    .listings {
        height: 690px;
    }

    .car-listings {
        height: 640px;
    }

    .cars-container {
        height: 650px;
    }


}


@media screen and (max-width:415px) {


    .car-container {
        width: 294px;
        height: 234px;
    }

    .car-container img {
        width: 293.5px;
        height: 163px;
    }


    .listings,
    .car-listings {
        width: 340px;
    }



}



@media screen and (max-width:395px) {


    .car-container {
        width: 284px;
        height: 234px;
    }

    .car-container img {
        width: 283.5px;
        height: 163px;
    }


    .listings,
    .car-listings {
        width: 320px;
    }



}



@media screen and (max-width:375px) {
    .car-container {
        width: 250px;
        height: 234px;
    }

    .car-container img {
        width: 249.5px;
        height: 163px;
    }



    .listings,
    .car-listings {
        width: 290px;
    }

}



@media screen and (min-width:320px) and (max-width:375px) {
    .car-container {
        width: 230px;
        height: 234px;
    }

    .car-container img {
        width: 229.5px;
        height: 163px;
    }



    .listings,
    .car-listings {
        width: 250px;
    }



    .listings {
        height: 680px;
    }

    .car-listings {
        height: 600px;
    }

    .cars-container {
        height: 600px;
    }

}