.login-page-container {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F4F7;
    height: 100%;
}

.login-background {
    background-image: url('../../images/why-kei-8e2gal_GIE8-unsplash@2x.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
}


.login-background::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url('../../images/Rectangle\ 14640@2x.png');
    background-size: cover;
    background-attachment: fixed;

    background-repeat: no-repeat;
    opacity: 69%;
}



.login-container {
    position: absolute;
    height: 900px;
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 5px 15px #00347026;
border-radius: 5px;
}

.login-form {
    display: flex;
    flex-direction: column;
    margin-top: 45.86px;
    font-size: 16px;
    color: #0C0C21;
    width: 500px;
}

.login-form label {
    margin-bottom: 9.9px;
}

#email {
    margin-bottom: 40.93px;
    border: 0.5px solid var(--unnamed-color-0c0c21);
    border: 0.5px solid #0C0C21;
    border-radius: 5px;
}

#password {
    border: 0.5px solid var(--unnamed-color-0c0c21);
    border: 0.5px solid #0C0C21;
    border-radius: 5px;
    width: 500px;

}

#email,
#password,
#login-submit {
    height: 42px;
    padding: 5px;
    font-size: 16px;
}

#login-submit {
    background: #1F1DEB 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

#login-submit:hover {
    opacity: 0.7;
}

.login-logo {
    width: 320.2px;
    height: 74.52px;
    margin-top: 93px;

}

.login-container span {
    font-size: 20px;
    font-weight: bold;
    margin-top: 57.48px;
}

.password-field {
    width: 500px;
    position: relative;
    margin-bottom: 135.7px;
    display: flex;
    align-items: center;
}

.password-eye {
    position: absolute;
    right: 10px;
    cursor: pointer;
}




@media screen and (min-width:1951px) {}



@media screen and (max-width:1950px) {}

@media screen and (max-width:1800px) {}


@media screen and (max-width:1700px) {

    .login-container {
        height: 700px;
        width: 500px;
    }

    .login-form,
    .password-field,
    #password {
        width: 400px;
    }

    #email {
        margin-bottom: 30px;
    }

    .password-field {
        margin-bottom: 80px;
    }


}

@media screen and (max-width:1550px) {

    .login-container {
        height: 600px;
        width: 400px;
    }

    .login-form,
    .password-field,
    #password {
        width: 300px;
    }

    #email {
        margin-bottom: 20px;
    }

    .password-field {
        margin-bottom: 50px;
    }


}




@media screen and (max-width:455px) {


    .login-logo {
        height: 57.25px;
        width: 246px;
    }

    .login-container {
        height: 500px;
        width: 350px;
    }

    .login-container span {
        margin-top: 30px;
    }

    .login-form {
        margin-top: 20px;
    }

    #email {
        margin-bottom: 20px;
    }

    .password-field {
        margin-bottom: 30px;
    }


}




@media screen and (min-width:320px) and (max-width:375px) {



    .login-container {
        height: 500px;
        width: 300px;
    }

    .login-container span {
        margin-top: 30px;
    }

    .login-form {
        margin-top: 20px;
    }

    
    .login-form,
    .password-field,
    #password {
        width: 200px;
    }

    #password{
        padding-right: 0;
    }

    #email {
        margin-bottom: 20px;
    }

    .password-field {
        margin-bottom: 30px;
    }


}
